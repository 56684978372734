import React, { Component } from 'react'
import i18n from "i18next";

export class NoLink extends Component {
    render() {
        return (
            <div>
                 <div style={{marginTop: '50px', color: 'white'}}>{i18n.t('page.not.found')}</div>
            </div>
        )
    }
}

export default NoLink
