import logo from './getfy.svg';
import logoWhite from './logo-white.svg';
import logoBlack from './logo-black.svg';
import './App.css';
import { Component } from 'react';
import NoLink from './NoLink';
import OkLink from './OkLink';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import { BoxLoading, CoffeeLoading } from 'react-loadingg';
import isok from './isok.png';
import ThemeManager from './ThemeManager';
import MetaTags from 'react-meta-tags';
import Subscribe from './Subscribe';
import i18n from "i18next";
import LangOption from './LangOption';

class App extends Component {
  
  constructor(props){
    super(props);
   
    //setInterval(() => this.showProducts(),10);
    var linkCode = window.location.href.replace(window.location.origin, "");
    linkCode = linkCode.replace("/", "");
   
    
    this.state = { linkCode: linkCode, notvalidcode: false, generalInfo: [], isReady: false, isStopping: false, openLoader: false, openSuccess: false};
    
  }


  componentDidMount () {
    this.getLinkInfo(this.state.linkCode);
  }

  getLinkInfo = (code) => {

    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
                code: code
              })
      };
    
          const apiUrl = 'https://getfy-business.com/user/v3/link/';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {

      let themeManager = ThemeManager.getInstance();

      if(r.theme !== undefined && r.theme !== null){
        themeManager.setBackgroundColor(r.theme.background_color);
        themeManager.setSelectionColor(r.theme.selection_color);
        themeManager.setMainTextColor(r.theme.main_text_color);
        themeManager.setSecondaryTextColor(r.theme.secondary_text_color);
        themeManager.setButtonTextColor(r.theme.button_text_color);
        themeManager.setItemBackgroundColor(r.theme.item_background_color);
        themeManager.setErrorColor(r.theme.error_color);
      }

      var _backgroundColor = themeManager.getBackgroundColor();
      this.elaborateColorIntensity(_backgroundColor);

      var generalInfo = [];
      generalInfo.title = r.info.title;
      generalInfo.message = r.info.message;
      generalInfo.link = r.info.url;
      generalInfo.type = r.info.type;
      generalInfo.linkcode = code;

      this.setState({ notvalidcode: false, generalInfo: generalInfo, isReady: true});

      
    }).catch(e => {
      let themeManager = ThemeManager.getInstance();
      var _backgroundColor = themeManager.getBackgroundColor();
      this.elaborateColorIntensity(_backgroundColor);
      this.setState({ notvalidcode: true, generalInfo: [], isReady: true});
    });
  }

  toggle = (e) => {
    this.setState({ openLoader: false, openSuccess: false});
  }

  stopMarketing = () => {

    if(!this.state.isStopping){
      this.setState({ isStopping: true, openLoader: true});

    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
                code: this.state.linkCode
              })
      };
    
          const apiUrl = 'https://getfy-business.com/user/v3/link/stop';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {

      this.setState({ isStopping: false, openSuccess: true, openLoader: false});
      
    }).catch(e => {
      this.setState({ isStopping: false, openSuccess: true, openLoader: false});
    });

    }

  }


  elaborateColorIntensity = (bgColor) => {
    return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? this.setState({ logo: 'dark' }) : this.setState({ logo: 'light' });
  }

  render(){
    

    let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

    return (

      <div>
   { this.state.isReady &&
      <div className="App" style={{ backgroundColor: _backgroundColor}}>
     
      {this.state.isReady &&
      <MetaTags>
       <meta name="theme-color" content={_backgroundColor} />           
      </MetaTags>
      }

  { this.state.logo === 'dark' && 
    <img alt="Getfy" style={{marginTop: '20px', height: '18px'}} src={logoBlack} />
    }

  { this.state.logo === 'light' && 
    <img alt="Getfy" style={{marginTop: '20px', height: '18px'}} src={logoWhite} />
    }

   <LangOption />

     { (this.state.isReady && this.state.notvalidcode) &&
      <NoLink />
     }

    { (this.state.isReady && !this.state.notvalidcode && this.state.generalInfo != [] && this.state.generalInfo.type == 'link') &&
     <OkLink stopMarketing={this.stopMarketing} info={this.state.generalInfo} />
      }

    { (this.state.isReady && !this.state.notvalidcode && this.state.generalInfo != [] && this.state.generalInfo.type == 'place') &&
     <Subscribe info={this.state.generalInfo} />
      }

                  <Drawer
                    open={this.state.openLoader}
                        onRequestClose={this.toggle}
                        modalElementClass="modalPrefix">
                            <div>
                            <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('wait')}</div>
                    </div>
                    <BoxLoading color={_selectionColor} />
                    
                    </div>
                  </Drawer>

                  <Drawer
                    open={this.state.openSuccess}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div  onClick={this.toggle}>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('stop.done')}</div>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{marginTop: '10px'}} src={isok} />
                    </div>
                   
                   <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '10px', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('close')}</div>
                    </div>
                   
                    </div>
                    </Drawer>

    </div>
    }
    </div>
  );

  }
}

export default App;
