import React, { Component } from 'react'
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import SubscribeForm from './SubscribeForm';
import ThemeManager from './ThemeManager';

export class Subscribe extends Component {

    constructor(props){
        super(props);

        this.state = { linkInfo: props.info, s: 0, openCancel: false};

    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _errorColor = themeManager.getErrorColor();


        return (
            <div>
                
                <div style={{margin: '10px', marginTop: '50px', color: _mainTextColor, fontWeight: 'bold', fontSize: '22px'}}>{this.state.linkInfo.title}</div>
               

                <SubscribeForm linkcode={this.state.linkInfo.linkcode} />
            </div>
        )
    }
}

export default Subscribe
