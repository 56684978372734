import React, { Component } from 'react'
import '../App.css';
import ThemeManager from '../ThemeManager';
import i18n from "i18next";

export class NumberConfirmation extends Component {

    constructor(props){
        super(props);
        var phoneSpace = props.number;
     
        phoneSpace  = phoneSpace.slice(0, 3) + " " + phoneSpace.slice(3);
        phoneSpace  = phoneSpace.slice(0, 6) + " " + phoneSpace.slice(6);
        phoneSpace  = phoneSpace.slice(0, 9) + " " + phoneSpace.slice(9);

        this.state = { codeValue: '', phone: phoneSpace, prefix: props.prefix, error: false, canCancel: props.canCancel};

        
       
    }

    cancelInsert = (e) => {

        this.props.cancel();

    }


    static getDerivedStateFromProps(props, current_state) {
        if (current_state.error !== props.error) {
            return {
             error: props.error          
            }
        }
        return null;
    }

    insertNumber = (e) => {

        var insert = String(e.target.value);
       
        if(!isNaN(insert)&&e.target.value.length<5){
            this.setState({ codeValue: insert});
        }
    }

    confirmCode = (e) => {
       
        if(this.state.codeValue.length === 4 && !isNaN(this.state.codeValue)){
            this.props.verify(this.state.codeValue);
        }
    }

    render() {

    let themeManager = ThemeManager.getInstance();
    const _backgroundColor = themeManager.getBackgroundColor();
    const _selectionColor = themeManager.getSelectionColor();
    const _mainTextColor = themeManager.getMainTextColor();
    const _secondaryTextColor = themeManager.getSecondaryTextColor();
    const _itemBackgroundColor = themeManager.getItemBackgroundColor();
    const _buttonTextColor = themeManager.getButtonTextColor();
    const _errorColor = themeManager.getErrorColor();


        return (
            <div>
              <div style={{marginTop: '80px'}}>
            <p style={{color: _secondaryTextColor, fontSize: '13px'}}>{i18n.t('insert.activation.code')}</p>
            <p style={{marginTop: '0px', color: _mainTextColor, fontSize: '14px', fontWeight: 'bold'}}>+{this.state.prefix} {this.state.phone}</p>
            </div>
                
            { this.state.error && <p style={{ fontSize: '12px', color: _errorColor }}>{i18n.t('invalid.code')}</p> }

            <div  style={{marginTop: '50px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: _mainTextColor, fontWeight: 'thin', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: _itemBackgroundColor, outlineWidth: '0', fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="tel" 
                name="code"
                value={this.state.codeValue}
                placeholder={i18n.t('code')}
                onChange={this.insertNumber}
                />
               </div>


                <div  style={{marginTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.confirmCode}
            >
                    {i18n.t('confirm')}</p>
                    </div>
                   
             { this.state.canCancel && <div style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color:_secondaryTextColor, fontSize: '12px'}}>{i18n.t('wrong.number')}</p>
            <p onClick={this.cancelInsert} style={{cursor: 'pointer', marginLeft: '10px', color: _mainTextColor, fontSize: '12px', fontWeight: 'bold'}}>{i18n.t('cancel')}</p>
            </div> }
              
            </div>
        )
    }
}

export default NumberConfirmation
