import React, { Component } from 'react'
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class OkLink extends Component {

    constructor(props){
        super(props);

        this.state = { linkInfo: props.info, s: 0, openCancel: false, countdown: 3};

    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { countdown } = this.state

            if (countdown > 0) {
                this.setState(({ countdown }) => ({
                    countdown: countdown - 1
                }))
            }
            if (countdown === 0) {
                window.location.replace(this.state.linkInfo.link);
            } 
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    stopMarketing = (e) => {

        if(this.state.s>1){
            clearInterval(this.myInterval)
            this.setState({ openCancel: true});
        }

        this.setState({ s: this.state.s+1});

    }

    toggle = (e) => {
        this.setState({ openCancel: false});
        this.myInterval = setInterval(() => {
            const { countdown } = this.state

            if (countdown > 0) {
                this.setState(({ countdown }) => ({
                    countdown: countdown - 1
                }))
            }
            if (countdown === 0) {
                window.location.replace(this.state.linkInfo.link);
            } 
        }, 1000)
    }

    blockService = (e) => {
        this.toggle();
        this.props.stopMarketing();
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _errorColor = themeManager.getErrorColor();
        
        return (
            <div>
                 <div style={{margin: '50px', marginTop: '50px', color: _mainTextColor, fontWeight: 'bold', fontSize: '22px'}}>{this.state.linkInfo.title}</div>
                 <div style={{margin: '15px', marginTop: '20px', color: _mainTextColor, fontSize: '12px'}}>{this.state.linkInfo.message}</div>

                { this.state.linkInfo.link != '' &&
                 <div><div  style={{ marginTop: '25px',textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={()=> window.open(this.state.linkInfo.link, "_blank")}>

            {i18n.t('go.to.link')}</p>
                    </div>
                    </div>
                }


            <div onClick={this.stopMarketing} style={{ cursor: 'pointer', marginTop: '10px', color: _secondaryTextColor, fontSize: '50px', fontWeight: 'bold'}}>{this.state.countdown}</div>     



            <div onClick={this.stopMarketing} style={{ position: 'absolute',textAlign: 'center', width: '100%', bottom: '15px', cursor: 'pointer', marginTop: '80px', color: _secondaryTextColor, fontSize: '10px', fontWeight: 'normal'}}>{i18n.t('stop.sms')}</div>     


                    <Drawer
                    open={this.state.openCancel}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('are.you.sure')}<br />{i18n.t('receive.promotions')}</div>
                    </div>
                    <div  style={{marginTop: '25px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.blockService}
            >{i18n.t('stop.service')}</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('cancel')}</div>
                    </div>
                    </div>
                    </Drawer>

            </div>
        )
    }
}

export default OkLink
