import React, { Component } from 'react'
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import ThemeManager from './ThemeManager';
import Login from './Login/Login';

export class SubscribeForm extends Component {


    constructor(props){
        super(props);

        this.state = { linkcode: props.linkcode, mobileValue: '', showMenu: false, numberSize: '12px', openPrefix: false, prefixValue: '39', prefixDisplay: '🇮🇹 +39'};

        
       
    }

    toggle = () => {
        this.setState({ openPrefix: false})
    }
       
    showPrefixSelection = (e) => {
        this.setState({ openPrefix: true});
    }

    selectPrefix = (e) => {
       
       this.setState({ prefixDisplay: e.target.innerHTML, prefixValue: e.target.id});
       this.toggle();
    }

    insertNumber = (e) => {

        var insert = String(e.target.value);
        
        if(!isNaN(insert)){
            this.setState({ mobileValue: insert});
        }

        if(e.target.value.length>0){
            this.setState({ numberSize: '14px'});
        }else{
            this.setState({ numberSize: '12px'});
        }
    }

    


    confirmNumber = (e) => {
       
        if(this.state.mobileValue.length > 8 && this.state.mobileValue.length < 12 && !isNaN(this.state.mobileValue)){
            this.props.login(this.state.mobileValue,this.state.prefixValue);
        }
    }

    render() {

      

        return (
            <div>
                

           <Login linkcode={this.state.linkcode} />


            </div>
        )
    }
}

export default SubscribeForm
