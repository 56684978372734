import React, { Component } from 'react'
import '../App.css';
import ThemeManager from '../ThemeManager';
import i18n from "i18next";

export class Register extends Component {

    intervalID = 0;

    constructor(props){
        super(props);

        let themeManager = ThemeManager.getInstance();
        let _secondaryTextColor = themeManager.getSecondaryTextColor();
        let _itemBackgroundColor = themeManager.getItemBackgroundColor();
     
        this.state = { nameValue: '', surnameValue: '', error: false, ck1: false, ck2: false, animationShake: '', animationShake2: '',
        date: new Date("2009-12-31"),
        openConfirm: false,
        openConfirmChange: false,
        birthDateString: '-',
        isProcessing: false, 
        day: '0', 
        month: '0', 
        year: '0', 
        dayColor: _secondaryTextColor,
        monthColor: _secondaryTextColor,
        yearColor: _secondaryTextColor,
        itemsColor: [_itemBackgroundColor, _itemBackgroundColor, _itemBackgroundColor], selected: -1, showBirthDate: false, showName:true, showSex: false};    
    }


    static getDerivedStateFromProps(props, current_state) {
        if (current_state.error !== props.error) {
            return {
             error: props.error          
            }
        }
        return null;
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    insertData = (e) => {
        var insert = e.target.value;
        insert =  this.capitalizeFirstLetter(insert);
        this.setState({ [e.target.name] : insert});
    }


    confirmRegister = (e) => {

            if(this.state.showName){
                if(this.state.ck1 && this.state.ck2 && this.state.nameValue.length>0 && this.state.surnameValue.length>0){
                    this.setState({ showName: false, showBirthDate: true });
                }else if(!this.state.ck1){
                    this.setState({ animationShake: 'animationShake'}); 
                    this.intervalID = setInterval(this.closeAnimation, 300);
                }else if(!this.state.ck2){
                    this.setState({ animationShake2: 'animationShake'}); 
                    this.intervalID = setInterval(this.closeAnimation, 300);
                }
            }else if(this.state.showBirthDate){
                if(this.state.day>0 && this.state.day<32 && this.state.month>0 && this.state.month<13 && this.state.year>1900 && this.state.year<2011){
                    this.setState({ showSex: true, showBirthDate: false });
                }
            }else{
                if(this.state.selected != -1){
                    var datestring = this.state.year+'/'+this.state.month+'/'+this.state.day;
                    var date = new Date(datestring);
                    this.props.register(this.state.nameValue, this.state.surnameValue, date, this.state.selected);
                }
            }
        
    }

    closeAnimation = (e) => {
        this.setState({ animationShake: '', animationShake2: ''}); 
        clearInterval(this.intervalID);
    }

    onItemHover = (e) => {

        let themeManager = ThemeManager.getInstance();
        let _itemBackgroundColor = themeManager.getItemBackgroundColor();

        if(this.state.selected === -1){
            var itemsColor = this.state.itemsColor;
            itemsColor[e.target.id] = _itemBackgroundColor;
            this.setState({ itemsColor: itemsColor});
        }

    }

    onItemLeave = (e) => {

        let themeManager = ThemeManager.getInstance();
        let _itemBackgroundColor = themeManager.getItemBackgroundColor();

        if(this.state.selected === -1){
            var itemsColor = this.state.itemsColor;
            itemsColor[e.target.id] = _itemBackgroundColor;
            this.setState({ itemsColor: itemsColor});
        }

    }

    onItemClick = (e) => {

        let themeManager = ThemeManager.getInstance();
        let _selectionColor = themeManager.getSelectionColor();
        let _mainTextColor = themeManager.getMainTextColor();
        let _secondaryTextColor = themeManager.getSecondaryTextColor();
        let _itemBackgroundColor = themeManager.getItemBackgroundColor();

        if(this.state.selected !== -1){
            var itemsColor = [_itemBackgroundColor, _itemBackgroundColor, _itemBackgroundColor];
            itemsColor[e.target.id] = _selectionColor;
            this.setState({ itemsColor: itemsColor, selected: e.target.id});
        }else{
            var itemsColor = this.state.itemsColor;
            itemsColor[e.target.id] = _selectionColor;
            this.setState({ itemsColor: itemsColor, selected: e.target.id});
        }
    }

    checkboxAction = (e) => {
        var checkBox = e.target.name;
        if(checkBox === "ck1"){
            if(this.state.ck1){
                this.setState({ ck1: false}); 
            }else{
                this.setState({ ck1: true}); 
            }
        }else if(checkBox === "ck2"){
            if(this.state.ck2){
                this.setState({ ck2: false}); 
            }else{
                this.setState({ ck2: true}); 
            }
        }
        
    }

    changeYear = (e) => {
      
        let themeManager = ThemeManager.getInstance();
          let _mainTextColor = themeManager.getMainTextColor();
          let _secondaryTextColor = themeManager.getSecondaryTextColor();
  
        var newValue = e.target.value;
        var colorValue = _mainTextColor;
        if(newValue==="0"){
          colorValue = _secondaryTextColor;
        }
        this.setState( { year: newValue, error: false, yearColor: colorValue });
  
      }
  
      changeMonth = (e) => {
  
        let themeManager = ThemeManager.getInstance();
          let _mainTextColor = themeManager.getMainTextColor();
          let _secondaryTextColor = themeManager.getSecondaryTextColor();
  
        var newValue = e.target.value;
        var colorValue = _mainTextColor;
        if(newValue==="0"){
          colorValue = _secondaryTextColor;
        }
        this.setState( { month: newValue, error: false, monthColor: colorValue  });
  
      }
  
      changeDay = (e) => {
  
        let themeManager = ThemeManager.getInstance();
          let _mainTextColor = themeManager.getMainTextColor();
          let _secondaryTextColor = themeManager.getSecondaryTextColor();
  
        var newValue = e.target.value;
        var colorValue = _mainTextColor;
        if(newValue==="0"){
          colorValue = _secondaryTextColor;
        }
        this.setState( { day: newValue, error: false, dayColor: colorValue });
  
      }

    render() {

        const days = [];
        for(var i=1; i<32; i++){
          days.push(i);
        }
  
        const years = [];
        for(var i=2010; i>1909; i--){
          years.push(i);
        }

        let themeManager = ThemeManager.getInstance();
    const _backgroundColor = themeManager.getBackgroundColor();
    const _selectionColor = themeManager.getSelectionColor();
    const _mainTextColor = themeManager.getMainTextColor();
    const _secondaryTextColor = themeManager.getSecondaryTextColor();
    const _itemBackgroundColor = themeManager.getItemBackgroundColor();
    const _buttonTextColor = themeManager.getButtonTextColor();
    const _errorColor = themeManager.getErrorColor();


        return (
            <div>
             

            <div style={{marginTop: '50px'}}>
            <p style={{color: _secondaryTextColor, fontSize: '14px'}}>{i18n.t('insert.data')}</p>
            <p style={{marginTop: '-12px', color: _secondaryTextColor, fontSize: '14px'}}>{i18n.t('to.continue.register')}</p>
            </div>
                
                
            { this.state.error && <div style={{ fontSize: '14px', color: 'red' }}>{i18n.t('request.error.reload')}</div> }
            {this.state.showName &&
            <div>
            <div style={{marginTop: '25px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
                <div style={{ height: '45px', borderRadius: '8px', width: '250px',backgroundColor: _itemBackgroundColor }}>
                <input 
                style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '250px',
                 outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
                name="nameValue"
                value={this.state.nameValue}
                placeholder={i18n.t('name')}
                onChange={this.insertData}
                />
                </div>
                
            </div>

            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
                <div style={{ height: '45px', borderRadius: '8px', width: '250px'}}>
                <input 
                style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '250px',
                 outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
                name="surnameValue"
                value={this.state.surnameValue}
                placeholder={i18n.t('surname')}
                onChange={this.insertData}
                />
                </div>
                
            </div>
            </div>}

            {this.state.showBirthDate &&
            <div>
            <select className="nosafari" style={{ marginTop: '20px', width: '250px', height: '45px', backgroundColor: _itemBackgroundColor,
             color: _mainTextColor, border: '0px solid', borderRadius: '8px', fontWeight: 'normal', textAlign: 'center'
             }} value={this.state.day} onChange={this.changeDay}>
            <option value="0">{i18n.t('birth.day')}</option>
            { days.map((day, i) =>
                <option key={i} value={day}>{day}</option>
                )
            }
            
            </select>
            <br />
            <select className="nosafari" style={{ marginTop: '15px', width: '250px', height: '45px', backgroundColor:  _itemBackgroundColor,
             color: _mainTextColor, border: '0px solid', borderRadius: '8px', fontWeight: 'normal', textAlign: 'center'
             }} value={this.state.month} onChange={this.changeMonth}>
            <option value="0">{i18n.t('birth.month')}</option>
            <option value="1">{i18n.t('jan')}</option>
            <option value="2">{i18n.t('feb')}</option>
            <option value="3">{i18n.t('mar')}</option>
            <option value="4">{i18n.t('apr')}</option>
            <option value="5">{i18n.t('may')}</option>
            <option value="6">{i18n.t('jun')}</option>
            <option value="7">{i18n.t('jul')}</option>
            <option value="8">{i18n.t('aug')}</option>
            <option value="9">{i18n.t('sep')}</option>
            <option value="10">{i18n.t('oct')}</option>
            <option value="11">{i18n.t('nov')}</option>
            <option value="12">{i18n.t('dec')}</option>
            </select>
            <br />
            <select className="nosafari" style={{ marginTop: '15px', width: '250px', height: '45px', backgroundColor:  _itemBackgroundColor,
             color: _mainTextColor, border: '0px solid', borderRadius: '8px', fontWeight: 'normal', textAlign: 'center'
             }} value={this.state.year} onChange={this.changeYear}>
            <option value="0">{i18n.t('birth.year')}</option>
            { years.map((year, i) =>
                <option key={i} value={year}>{year}</option>
                )
            }
            
            </select>
            </div> }

            {this.state.showSex &&
            <div>
            <div style={{ marginTop: '20px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
               
               <div style={{marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', backgroundColor: this.state.itemsColor[0], borderRadius: '8px', color: _mainTextColor, textAlign: 'center', 
               justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', cursor: 'pointer', fontSize: '13px'}} 
               id="0" 
               onMouseOver={this.onItemHover} 
               onMouseLeave={this.onItemLeave}
               onClick={this.onItemClick} >
                   {i18n.t('man')}
               </div>

              
               
                <div style={{marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', backgroundColor: this.state.itemsColor[1], borderRadius: '8px', color: _mainTextColor, textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', cursor: 'pointer', fontSize: '13px'}} 
                id="1" 
                onMouseOver={this.onItemHover} 
                onMouseLeave={this.onItemLeave}
                onClick={this.onItemClick} >
                    {i18n.t('woman')}
                </div>

                
                <div style={{marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', backgroundColor: this.state.itemsColor[2], borderRadius: '8px', color: _mainTextColor, textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', cursor: 'pointer', fontSize: '13px'}} 
                id="2" 
                onMouseOver={this.onItemHover} 
                onMouseLeave={this.onItemLeave}
                onClick={this.onItemClick} >
                    {i18n.t('other')}
                </div>

                </div>
                </div>}


                {this.state.showName &&
             <div>
             <div style={{marginTop: '20px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
             <div style={{marginLeft: '25px',  minHeight: '25px',  borderRadius: '8px', width: '260px', color: 'white', fontSize: '12px', textAlign: 'left'}}>
              <input className={this.state.animationShake} checked={this.state.ck1} onChange={this.checkboxAction} name="ck1" type="checkbox" /> 
              <a target="_blank" style={{ marginLeft: '5px', color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.getfy.it/agreements/terms-and-conditions.pdf">
              {i18n.t('accept.terms')}
                  </a>
                 
              </div>
 
              
              
          </div>
 
          <div style={{marginTop: '5px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              
            
 
              <div style={{marginLeft: '25px',  minHeight: '25px', borderRadius: '8px', width: '260px', color: 'white', fontSize: '12px', textAlign: 'left'}}>
              <input className={this.state.animationShake2} checked={this.state.ck2} onChange={this.checkboxAction} name="ck2" type="checkbox" /> 
              <a target="_blank" style={{ marginLeft: '5px', color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.getfy.it/agreements/privacy-policy">
              {i18n.t('accept.privacy')}
                  </a>
                 
              </div>
              
          </div>
          </div>}

            
                


                <div  style={{marginTop: '15px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.confirmRegister}
            >
                    {i18n.t('next')}</p>
                    </div>

<br /><br />
                    
              
            </div>
        )
    }
}

export default Register
